import { IFeedback } from './interfaces';
import goldmanSachsLogo from '@/ui/assets/customers/svg/goldmanSachs.svg';
import citibankLogo from '@/ui/assets/customers/svg/citibank.svg';
import loandepotLogo from '@/ui/assets/customers/svg/loanDepot.svg';
import creditSuisseLogo from '@/ui/assets/customers/svg/creditSuisse.svg';
import firstkeyLogo from '@/ui/assets/customers/svg/firstKey.svg';
import deutscheBankLogo from '@/ui/assets/customers/svg/deutscheBank.svg';
import onemainLogo from '@/ui/assets/customers/svg/oneMainFinancial.svg';
import crossRiverLogo from '@/ui/assets/customers/svg/crossRiver.svg';
import bankOfAmericaLogo from '@/ui/assets/customers/svg/bankOfAmerica.svg';
import willisLogo from '@/ui/assets/customers/svg/willisLease.svg';
import styles from './feedbacks.scss';

const generateSliderSettings = ({
  dots = false,
  arrows = false,
  infinite = false,
  autoplay = false,
  rows = 1,
  className,
}: {
  dots?: boolean,
  arrows?: boolean,
  infinite?: boolean,
  autoplay?: boolean,
  rows?: number,
  className?: string,
}) => {
  return {
    className,
    dots,
    arrows,
    infinite,
    speed: 500,
    slidesToShow: 4,
    initialSlide: 0,
    rows,
    ...(autoplay && {
      autoplay: true,
      autoplaySpeed: 3000,
    }),
    responsive: [
      {
        breakpoint: 1196,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          rows: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
        },
      },
    ],
  };
};

export const slidersSettingsTypes = {
  default: generateSliderSettings({
    autoplay: true,
    infinite: true,
    className: styles.grab,
    dots: true,
  }),
  grid: generateSliderSettings({
    dots: true,
    rows: 3,
  }),
};

export const feedbacksList: IFeedback[] = [
  {
    imageSrc: goldmanSachsLogo,
    imageAlt: 'Goldman Sachs',
    feedbackText: '“Thank you so much. Never had better service in my life. Thank you again!”',
    feedbackAuthor: 'Kaitlyn',
  },
  {
    imageSrc: citibankLogo,
    imageAlt: 'citibank',
    feedbackText: '“Thank you so much for all of your help with this transaction! We loved working with you and look forward to working together in following deals. Thank you again!"',
    feedbackAuthor: 'Adriana',
  },
  {
    imageSrc: loandepotLogo,
    imageAlt: 'loanDepot',
    feedbackText: '“Your team is always the best! I will never forget my Saturday morning fiasco when they completely saved me!”',
    feedbackAuthor: 'D.A.',
  },
  {
    imageSrc: goldmanSachsLogo,
    imageAlt: 'Goldman Sachs',
    feedbackText: '“I’m not just saying this but your customer service is some of the best I’ve ever seen in any space. We greatly value your teams responsiveness and the product you deliver.”',
    feedbackAuthor: 'Jeff',
  },
  {
    imageSrc: creditSuisseLogo,
    imageAlt: 'Credit Suisse',
    feedbackText: '“Our clients should be paying me to recommend your data room. It costs less, it’s faster and easier to use”',
    feedbackAuthor: 'Hakim',
  },
  {
    imageSrc: firstkeyLogo,
    imageAlt: 'FirstKey',
    feedbackText: '“I’m so happy we switched”',
    feedbackAuthor: 'T.D.',
  },
  {
    imageSrc: deutscheBankLogo,
    imageAlt: 'Deutsche Bank',
    feedbackText: '“The team has reached a consensus that Finsight is the best vendor we work with”',
    feedbackAuthor: 'Leigh',
  },
  {
    imageSrc: goldmanSachsLogo,
    imageAlt: 'Goldman Sachs',
    feedbackText: '“Thank you SO much! You guys are the best. This is why I recommend you for every deal."',
    feedbackAuthor: 'Stephanie',
  },
  {
    imageSrc: onemainLogo,
    imageAlt: 'OneMain Financial',
    feedbackText: '“I can’t believe how much progress you have made and how much you continue to innovate. Everything you do is valuable to us. Please give kudos to your team - I really mean it - this is great stuff.”',
    feedbackAuthor: 'D.S.',
  },
  {
    imageSrc: crossRiverLogo,
    imageAlt: 'cross river',
    feedbackText: '“Finsight customer service (as well as the platform itself) is top notch and we look forward to working together more.”',
    feedbackAuthor: 'Ryan',
  },
  {
    imageSrc: bankOfAmericaLogo,
    imageAlt: 'Bank Of America',
    feedbackText: '“Your team has the best customer service I’ve ever seen. Thank you so much!”',
    feedbackAuthor: 'Parul',
  },
  {
    imageSrc: willisLogo,
    imageAlt: 'Willis Lease',
    feedbackText: '“As always exceptionally quick and fanatical support. I’ve never worked with a support group as responsive as the Finsight team. It is a refreshing and pleasant experience.”',
    feedbackAuthor: 'J.W.',
  },
];
